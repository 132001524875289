import React from 'react';
import '../styles/Header.css';
import { Navbar, Container, Offcanvas, Nav, Row, Col } from 'react-bootstrap'

function Header() {
  return (
    <Row className='bg-body-tertiary'>
      <Col>
      <Navbar  expand={'sm'} >
    <Container >
      <Navbar.Brand href="#">
      <img src={require('../assets/sattva-logo.png')} className='header-logo-img' />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-sm`} />
      <Navbar.Offcanvas
        id={`offcanvasNavbar-expand-sm`}
        aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
          <img src={require('../assets/sattva-logo.png')} className='header-logo-img' />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="justify-content-end flex-grow-1 pe-3">
            <Nav.Link href="#action1" className='navLink' >Home</Nav.Link>
            <Nav.Link href="#about-us" className='navLink' >About us</Nav.Link>
            <Nav.Link href="#pricing" className='navLink' >Pricing</Nav.Link>
            <Nav.Link href="#gallery" className='navLink' >Gallery</Nav.Link>
            <Nav.Link href="#location" className='navLink' >Location</Nav.Link>
            
          </Nav>
         
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Container>
  </Navbar>
      </Col>
    </Row>
  
 
  )
}

export default Header