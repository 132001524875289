import React,{useState} from 'react';
import '../styles/SeventhSec.css';
import '../styles/SecondSec.css';
import { Row, Col, Button } from 'react-bootstrap'
import ImageSlider from '../utils/ImageSlider';
import Mod from '../utils/Mod';

function SeventhSec() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


    const images = [require('../assets/plan/parkcubix_masterplan-scaled.jpg'), require('../assets/plan/1BHK_Unit-Type-4.jpg'), require('../assets/plan/2BHK_Unit-Type-2-TV2.jpg'), require('../assets/plan/2.5BHK_Unit-Type-3.jpg'), require('../assets/plan/2BHK_Unit-Type-6.jpg'), require('../assets/plan/2BHK_Unit-Type-6-TV2.jpg'), require('../assets/plan/3BHK_Unit-Type-1.jpg'), require('../assets/plan/3BHK_Unit-Type-1-1.jpg'), require('../assets/plan/3BHK_Unit-Type-5.jpg'), require('../assets/plan/3BHK_Unit-Type-5A.jpg')];
    const texts = ['Masterplan', 'Unit Plan - 1BHK', 'Unit Plan - 2BHK', 'Unit Plan - 2BHK', 'Unit Plan - 2.5BHK', 'Unit Plan - 3BHK', 'Unit Plan - 3BHK', 'Unit Plan - 3BHK', 'Unit Plan - 3BHK', 'Unit Plan - 3BHK'];
  
  return (
    <>
        <Row className='seventh-row'>
            <Col className='col-12 text-center'>
                <h2 className='heading-title'>Project Plans</h2>
                <p className='second-para-sub text-center' >2 Side Open Apartments for Maximum Space Utilisation & Ample Sunlight and Ventilation</p>
                <div className='div-image-slider'><ImageSlider images={images} texts={texts} /></div>
                <Button className='seventh-button' onClick={handleShow}>Download Floorplans</Button>
            </Col>
        </Row>
        <Mod show={show} handleClose={handleClose} title={'Download Brochure and Floorplans'} />
    </>
  )
}

export default SeventhSec