import React,{useState} from 'react';
import '../styles/SecondSec.css';
import { Button, Col, Row } from 'react-bootstrap';
import Mod from '../utils/Mod';

function SecondSec() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <>
    <Row className='Second-Sec-First-Row' id='about-us'>
        <Col className='text-center'>
            <h2 className='heading-title'>Sattva Park Cubix</h2>
            <h3 className='sub-title'>Ready to Occupy 2 BHK & 3 BHK Apartments For Sale in Devanahalli</h3>
        </Col>
    </Row>
    <Row  className='Second-Sec-Sec-Row'>
        <Col className='col-12 col-md-7 Second-Sec-Sec-Col text-center'>
            <p className='second-para-sub'>Sattva Park Cubix offers <b>Ready To Occupy OC Recv. 2BHK &amp; 3BHK Apartments in
Devanahalli.</b> The project has a total of 18 acres of land with 1620 Premium 2 BHK Homes &amp; 3
BHK Homes. Sattva Park Cubix offers 50+ lifestyle amenities with 2 world class clubhouses &amp;
79&amp; Open Green Space.</p>
            <p className='second-para-sub'>Sattva Park Cubix Apartments is located in one of the fastest growing areas Devanahalli, North
Bangalore. Just 10 Mins From Bangalore International Airport &amp; 20 Mins Drive to Nandi Hills.
Close proximity to multiple IT Tech Parks, Educational Hubs, Hospitals &amp; Lifestyle Essentials.</p>
<Button className='button-second'  onClick={handleShow}>Schedule a Site Visit</Button>
        </Col>
        <Col className='col-12 col-md-5'>
            <img src={require('../assets/sattva-parkcubix-3.jpg')} className='Second-Sec-img' />
        </Col>
    </Row>
    <Mod show={show} handleClose={handleClose}  title={'Schedule a Site Visit'} />
    </>
  )
}

export default SecondSec