import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import FirstSec from '../components/FirstSec'
import SecondSec from '../components/SecondSec'
import ThirdSec from '../components/ThirdSec'
import ForthSec from '../components/ForthSec'
import FifthSec from '../components/FifthSec'
import SixthSec from '../components/SixthSec'
import SeventhSec from '../components/SeventhSec'

function Main() {
  return (
    <Container fluid>
    <Header />
    <FirstSec />
    <SecondSec />
    <ThirdSec />
    <ForthSec />
    <FifthSec />
    <SixthSec />
    <SeventhSec />
    <Footer />
    <a href='https://wa.me/919036705523' class='d-none d-md-block'>
      <img src={require('../assets/whatsapp.png')}
      style={{
          width: 50,
          height: 50,
          position: 'fixed',
          bottom: 10,
          right: 10, 
        }}
        alt="WhatsApp Icon"
        
      /></a>
      <a href='tel:+919036705523'  class='d-none d-md-block'>
      <img src={require('../assets/telephone.png')}
      style={{
          width: 50,
          height: 50,
          position: 'fixed',
          bottom: 10, 
          left: 10, 
        }}
        alt="Telephone Icon"
      
      /></a>
      <Row style={{position:'fixed',bottom:0,left:12,width:'100%',height:'5vh'}} className='d-md-none d-xs-block d-sm-block justify-content-center'>
        <Col xs={6} sm={6} style={{backgroundColor:'#2196f3',justifyContent:'center',alignItems:'center'}}>
        <a href='tel:+919036705523' style={{color:'white',textDecoration:'none'}} >
        <center style={{paddingTop:'3%'}}>
      <img src={require('../assets/telephone.png')}
      style={{
          width: 20,
          height: 20,
          
        }}
        alt="Telephone Icon"
      />Call Now</center></a>
        </Col>
        <Col xs={6} sm={6} style={{backgroundColor:'#4dc247'}}>
        
        <a href='https://wa.me/919036705523' style={{color:'white',textDecoration:'none'}}>
        <center  style={{paddingTop:'3%'}}>
      <img src={require('../assets/whatsapp.png')}
      style={{
          width: 20,
          height: 20,
         
        }}
        alt="WhatsApp Icon"
      /> Whatsapp</center></a>
        </Col>
      </Row>
    </Container>
  )
}

export default Main