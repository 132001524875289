import React, { useState } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import ContactForm from './ContactForm';

function Mod({ show, handleClose, title }) {
 
  return (
    <Modal show={show} onHide={handleClose}  closeButton={true}>

    <Modal.Body>
        <ContactForm  title={title}/>
        <Button variant="outline-light" style={{position:'absolute',top:20,right:20,color:'black',backgroundColor:'transparent'}}  onClick={handleClose}>X</Button>
    </Modal.Body>
    
  </Modal>
  )
}

export default Mod