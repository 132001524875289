// ImageSlider.js

import React, { useState } from 'react';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './IMgaeSlider.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "grey", borderRadius:'100%', border:'none',padding:0 }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "grey", borderRadius:'100%', border:'none',padding:0  }}
      onClick={onClick}
    />
  );
}

const ImageSlider = ({ images, texts }) => {
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,

            
          }
        },
        {
          breakpoint: 500,
          settings: {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,

          }
        }
      ]
  };


  return (
    <>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} onClick={() => setIsLightboxOpen(true)}>
            <img src={image} alt={`Slide ${index + 1}`} className='image-slider-size' />
            <div className="custom-text image-slider-text">{texts[index]}</div>
          </div>
        ))}
      </Slider>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={images[lightboxIndex]}
          nextSrc={images[(lightboxIndex + 1) % images.length]}
          prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() => setLightboxIndex((prev) => (prev + images.length - 1) % images.length)}
          onMoveNextRequest={() => setLightboxIndex((prev) => (prev + 1) % images.length)}
        />
      )}
    </>
  );
};

export default ImageSlider;
