import React,{useState} from 'react';
import '../styles/SecondSec.css';
import '../styles/SixthSec.css';
import '../styles/FirstSec.css';
import { Button, Col, Row } from 'react-bootstrap'
import Mod from '../utils/Mod';

function SixthSec() {
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
    <Row className='sixth-first-row' id='location'>
        <Col className='col-12 text-center'>
            <h2 className='heading-title'>LOCATION</h2>
        </Col>
    </Row>
        <Row  className='sixth-second-row'>
            <Col className='col-12 col-md-6 sixth-first-col'>
            <img src={require('../assets/Location.jpg')} className='sixth-sec-img' />
            </Col>
            <Col className='col-12 col-md-6 sixth-second-col'>
            <h3 style={{fontWeight:'bold',marginBottom:'5%'}}>Location</h3>
            <ul style={{listStyleType:'none',textAlign:'left'}}  className='paragraph-main-sixth'>
                <li>➤ 10 Mins From International Airport</li>
                <li>➤ 12 Mins to STRR</li>
                <li>➤ 20 Mins To Nandi Hills</li>
                <li>➤ 5 Mins From Devanahalli Town</li>
                <li>➤ 10 Mins To KIADB IT Park</li>
                <li>➤ 30 Mins From Manyata Tech Park</li>
                <li>➤ 10 Mins To Devanahalli Business Park</li>
                <li>➤ 25 Mins To Kirloskar Tech Park</li>
               
            </ul>
            <Button className='sixth-button'  onClick={handleShow}>Schedule a Site Visit</Button>
            </Col>
        </Row>
        <Mod show={show} handleClose={handleClose} title={'Schedule a Site Visit'} />
    </>
  )
}

export default SixthSec