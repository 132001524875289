import React from 'react';
import '../styles/ForthSec.css';
import '../styles/SecondSec.css';
import { Row, Col, Button } from 'react-bootstrap';
import ImageGallery from '../utils/ImageGallery';

function ForthSec() {

    const images = [
        { url: require('../assets/Gallery/1.jpg'), thumbnailUrl: require('../assets/Gallery/1.jpg') },
        { url: require('../assets/Gallery/2.jpg'), thumbnailUrl: require('../assets/Gallery/2.jpg') },
        { url: require('../assets/Gallery/3.jpg'), thumbnailUrl: require('../assets/Gallery/3.jpg') },
        { url: require('../assets/Gallery/4.jpg'), thumbnailUrl: require('../assets/Gallery/4.jpg') },
        { url: require('../assets/Gallery/5.jpg'), thumbnailUrl: require('../assets/Gallery/5.jpg') },
        { url: require('../assets/Gallery/6.jpg'), thumbnailUrl: require('../assets/Gallery/6.jpg') },
        { url: require('../assets/Gallery/7.jpg'), thumbnailUrl: require('../assets/Gallery/7.jpg') },
        { url: require('../assets/Gallery/9.jpg'), thumbnailUrl: require('../assets/Gallery/9.jpg') },
        { url: require('../assets/Gallery/8.jpg'), thumbnailUrl: require('../assets/Gallery/8.jpg') },
        
        // Add more images as needed
      ];
  return (
    <>
    <Row className='Second-Sec-First-Row' id='gallery'>
        <Col className='text-center'>
            <h2 className='heading-title'>Project Gallery</h2>
            <h3 className='sub-title'>2 BHK Flats in Devanahalli &amp; 3 BHK Flats in Devanahalli</h3>
        </Col>
    </Row>
    <Row  className='Second-Sec-Sec-Row'>
        
        <Col className='col-12'>
        <ImageGallery images={images} />
        </Col>
    </Row>
    </>
  )
}

export default ForthSec