import React,{useState} from 'react';
import '../styles/ThirdSec.css';
import '../styles/SecondSec.css';
import { Button, Col, Row } from 'react-bootstrap';
import Mod from '../utils/Mod';

function ThirdSec() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
        <Row className='text-center third-first-row' id='pricing'>
            <Col className='text-center'>
                <h2 className='heading-title'>PRICING</h2>
                
            </Col>
        </Row>
        <Row className='text-center third-second-row'  >
        <Col  className=' col-6 col-md-1 text-center d-none d-md-block'></Col>
                   
                    <Col className=' col-7 col-md-2 text-center div-col mx-auto'>
                        <div className='divFirst'><h4 className='div-heading'>2 BHK</h4></div>
                        <div  className='divSecond'>
                        <p className='div-para-sub'>999 Sq.ft</p>
                        <p className='div-para-price'>₹ 61.87* L Onwards</p>
                            <Button className='div-para-button'  onClick={handleShow}>Enquire Now</Button>
                        </div>
                    </Col>
                    <Col className=' col-7 col-md-2 text- div-col mx-auto'>
                        <div className='divFirst'><h4 className='div-heading'>2 BHK</h4></div>
                        <div  className='divSecond'>
                        <p className='div-para-sub'>1009 Sq.ft</p>
                        <p className='div-para-price'>₹ 62.58* L Onwards</p>
                            <Button className='div-para-button'  onClick={handleShow}>Enquire Now</Button>
                        </div>
                    </Col>
                    <Col className=' col-7 col-md-2 text-center div-col mx-auto'>
                        <div className='divFirst'><h4 className='div-heading'>3 BHK</h4></div>
                        <div  className='divSecond'>
                        <p className='div-para-sub'>1273 Sq.ft</p>
                        <p className='div-para-price'>₹ 78* L Onwards</p>
                            <Button className='div-para-button'  onClick={handleShow}>Enquire Now</Button>
                        </div>
                    </Col>
                    <Col className=' col-7 col-md-2 text-center div-col mx-auto'>
                        <div className='divFirst'><h4 className='div-heading'>3 BHK</h4></div>
                        <div  className='divSecond'>
                        <p className='div-para-sub'>1466 Sq.ft</p>
                        <p className='div-para-price'>₹ 89.65* L Onwards</p>
                            <Button className='div-para-button'  onClick={handleShow}>Enquire Now</Button>
                        </div>
                    </Col>
                    <Col  className=' col-6 col-md-1 text-center  d-none d-md-block'></Col>
                </Row>
                <Mod show={show} handleClose={handleClose}  title={'Schedule a Site Visit'}  />
    </>
  )
}

export default ThirdSec