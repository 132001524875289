import React from 'react';
import '../styles/FifthSec.css';
import '../styles/SecondSec.css';
import { Col, Row } from 'react-bootstrap';

function FifthSec() {
  return (
    <>
        <Row className='fifth-First-row'>
            <Col className='text-center'>
                <h2 className='heading-title'>Amenities</h2>
            </Col>
        </Row>
        <Row className='fifth-Second-row'>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/clubhouse.png')} className='icon-size' />
            <p className='icon-fifth-sub'>Club House</p>
            </Col>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/spa.png')} className='icon-size' />
            <p className='icon-fifth-sub'>Spa</p>
            </Col>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/playground.png')} className='icon-size' />
            <p className='icon-fifth-sub'>Kids Playing Area</p>
            </Col>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/parking.png')} className='icon-size' />
            <p className='icon-fifth-sub'>Car Parking</p>
            </Col>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/park.png')} className='icon-size' />
            <p className='icon-fifth-sub'>Central Park</p>
            </Col>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/swimming-pool.png')} className='icon-size' />
            <p className='icon-fifth-sub'>Swimming Pool</p>
            </Col>
          
            
            
        </Row>
        <Row className='fifth-Third-row'>
        <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/cafeteria.png')} className='icon-size' />
            <p className='icon-fifth-sub'>Cafeteria</p>
            </Col>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/24-hours-support.png')} className='icon-size' />
            <p className='icon-fifth-sub'>24/7 Securities</p>
            </Col>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/dumbbell.png')} className='icon-size' />
            <p className='icon-fifth-sub'>Gym</p>
            </Col>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/running.png')} className='icon-size' />
            <p className='icon-fifth-sub'>Jogging Track</p>
            </Col>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/court.png')} className='icon-size' />
            <p className='icon-fifth-sub'>Tennis Court</p>
            </Col>
            <Col className='col-6 col-md-2 text-center'>
            <img src={require('../assets/amenities/landscape (1).png')} className='icon-size' />
            <p className='icon-fifth-sub'>Landscaped Garden</p>
            </Col>
            
            
        </Row>
        
    </>
  )
}

export default FifthSec