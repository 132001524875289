import React from 'react';
import '../styles/FirstSec.css';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ContactForm from '../utils/ContactForm';

function FirstSec() {
  return (
   <>
    <Row className='firstSecRow'>
        <Col className='col-12 col-md-8 firstSecCol'>
          {/* <img src={require('../assets/sattva-logo.png')} className='firstSecImg' /> */}
        </Col>
        <Col className='col-12 col-md-4 SecondSecCol px-0 px-md-4'>
        <div className='SecondSecColDiv border-start border-end p-3'>
        <h2 className='heading-main'>Sattva Park Cubix - <span style={{backgroundColor:'#f2b605',color:'#000000'}}>Ready To Occupy</span></h2>
        <p className='paragraph-main'>2 BHK & 3 BHK Flats in Devanahalli From Rs.61 Lacs*</p>
        <hr/>

        <ul style={{listStyleType:'none',textAlign:'left'}}  className='paragraph-main'>
          <li>➤ 2 BHK Apartments &amp; 3 BHK Apartments</li>
          <li>➤ Located in One of The Fastest Growing Location</li>
          <li>➤ Just 10 Mins to International Airport</li>
          <li>➤ Just 10 Mins From Devanahalli Business Park</li>
          <li>➤ Just 20 Mins Drive to Nandi Hills</li>
          <li>➤ Total 18 Acres Land With 1620 Premium Apartments</li>
          <li>➤ 50+ Lifestyle Amenities &amp; 79% Open Area</li>
          <li>➤ 2 World Class Clubhouses</li>
          <li>➤ Ready to Move in Property</li>
          <li style={{backgroundColor:'#f2b605',color:'#000000'}}>➤ OC Already Received</li>
        </ul>

        <div style={{backgroundColor:'#262e8a'}} className='p-2'>
          <h4 className='heading-main' style={{color:'#ffffff'}}>2 BHK Flats Starts @ 61 Lacs* Onwards</h4>
          <h4 className='heading-main' style={{color:'#ffffff'}}>3 BHK Flats Starts @ 79 Lacs* Onwards</h4>
        </div>

      {/* <Form className='p-3'>
        <Form.Control type='text' name='FullName' placeholder='Name' className='mb-2'/>
        <Form.Control type='email' name='email' placeholder='Email' className='mb-2'/>
        <Form.Control type='number' name='mobile' placeholder='+91 ' className='mb-2'/>
        <center><Button className='w-50' style={{backgroundColor:'#262e8a'}}>Submit</Button></center>
      </Form> */}
      <ContactForm />



        </div>
   
        </Col>
    </Row>
    <Row className='iconRow '>
      <Col className='text-center col-6 col-md-2'>
        <img src={require('../assets/icons/place.png')} className='colIcon' />
        <h3 className='para-head'>Location</h3>
        <p className='para-sub'>Devanahalli, Bangalore</p>
      </Col>
      <Col className='text-center col-6 col-md-2'>
        <img src={require('../assets/icons/agreement.png')} className='colIcon' />
        <h3 className='para-head'>Type</h3>
        <p className='para-sub'>Premium Homes</p>
      </Col>
      <Col className='text-center  col-6 col-md-2'>
        <img src={require('../assets/icons/deal.png')} className='colIcon' />
        <h3 className='para-head'>Possession</h3>
        <p className='para-sub'>Ready To Move</p>
      </Col>
      <Col className='text-center  col-6 col-md-2'>
        <img src={require('../assets/icons/money.png')} className='colIcon' />
        <h3 className='para-head'>Price</h3>
        <p className='para-sub'>61 Lacs* Onwards</p>
      </Col>
      <Col className='text-center  col-12 col-md-2'>
        <img src={require('../assets/icons/double-bed.png')} className='colIcon' />
        <h3 className='para-head'>Bedroom</h3>
        <p className='para-sub'>2 & 3 BHK</p>
      </Col>
    </Row>
   </>
  )
}

export default FirstSec