import React, { useState, useRef } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';
import './ContactForm.css';

const Result = () =>{
    return(
        <p>Your message has been successfully sent. We'll contact you soon</p>
    )
  };

function ContactForm({title}) {
    const form = useRef();
  const [result, showResult] = useState(false);
  const navigation = useNavigate();


 
  const sendEmail = async  (e) => {
      e.preventDefault();
  
      
    
      emailjs.sendForm('service_gyb5wvi', 'template_dqa3u8h', form.current, 'JxxYo8z7JRpJc4wMR')        .then((result) => {
            console.log(result.text);
            navigation('/thankyou');
        }, (error) => {
            console.log(error.text);
        });
        // e.target.reset();
        // showResult(true);
   
    // emailjs.sendForm('service_x44fl28', 'template_dvqq5pq', form.current, '__-Bbi46YRqTAkieA')
    // .then((result) => {
    //     console.log(result.text);
    //     navigation('/thank-you');
    // }, (error) => {
    //     console.log(error.text);
    // });
    e.target.reset();
    showResult(true);
};

    // hide result after work 

    setTimeout(()=>{
      showResult(false);
    }, 5000);
  return (
    <div className='FormSection'>
        <h2 className=' text-center fs-4' style={{color:'#163664'}}>{title}</h2>
            <Form ref={form} onSubmit={sendEmail} className='p-3'>
             <Form.Control type='text' name='fullName' placeholder='Name' className='my-2'/>

        <Form.Control type='email' name='email' placeholder='Email' className='mb-2'/>

        <Form.Control type='number' name='phone' placeholder='+91 ' className='mb-2'/>

        <center><Button type="submit" className='w-50 contact-button'style={{backgroundColor:'#262e8a'}} >Submit</Button></center>
            </Form>
            </div>
  )
}

export default ContactForm