import React from 'react';
import '../styles/Footer.css';
import { Col, Row } from 'react-bootstrap'

function Footer() {
  return (
    <>
    <Row className='footer-row-desclaimer'>
      <Col>
        <p className='p-footer-des'><b>Disclaimer:</b>The content is for information purposes only and does not constitute an offer to avail of any service. Prices mentioned are subject to change without notice and properties mentioned are subject to availability. Images are for representation purposes only. This is the official website of an authorized marketing partner. We may share data with RERA registered brokers/companies for further processing. We may also send updates to the mobile number/email id registered with us. All Rights Reserved.</p>
      </Col>
    </Row>
      <Row className='footer-row-first'>
        <Col>
          <img src={require('../assets/Footer-logo-colored-white.png')} className='Footer-Img'/>
        </Col>
        <Col>
      <h3 className='footer-h3'>Project Location:</h3>
      <p  className='footer-p1'>Sattva Park Cubix - NH 207 Devanahalli, Bengaluru, Karnataka 562132</p>
        </Col>
      </Row>
      <Row className='footer-row-second'>
        <Col className='text-center text-light'>
          <p className='footer-p1'>Marketing Partner Growing Spaces</p>
        </Col>
      </Row>
    </>
  )
}

export default Footer