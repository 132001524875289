import { Routes, Route } from 'react-router-dom';
import './App.css';
import Main from './pages/Main';
import Thanks from './pages/Thanks';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Main/>} />
      <Route path='/thankyou' element={<Thanks/>} />
    </Routes>
  );
}


export default App;
